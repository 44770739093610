div.ten-thirty-one-exchange-info {
  align-items: flex-start;
  background-color: #413F40;
  color: #fff;
  display: flex;
  padding: 5% 10%;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section:nth-child(1) {
  margin-right: 5%;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section:nth-child(2) {
  margin-left: 5%;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section img {
  flex: 1;
  margin-bottom: 3%;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section h3 {
  flex: 2;
  font-size: 2.5em;
  font-weight: 600;
  line-height: 3rem;
  width: 80%;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section div.header-border-bottom {
  flex: 2;
  border-bottom: 3px solid #fff;
  margin-bottom: 5%;
  padding-top: 4%;
  width: 15%;
}

div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section p {
  flex: 2;
  font-size: 1.375em;
  line-height: 2.25rem;
  width: 90%;
}

@media screen and (max-width: 768px) {
  div.ten-thirty-one-exchange-info {
    flex-direction: column;
    padding: 3% 5%;
    text-align: center;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section {
    align-items: center;
    width: 80%;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section:nth-child(1) {
    margin: 5% auto;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section:nth-child(2) {
    margin: 5% auto;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section div.header-border-bottom {
    width: 25%;
  }
}

@media screen and (max-width: 425px) {
  div.ten-thirty-one-exchange-info {
    text-align: left;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section {
    align-items: flex-start;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section:nth-child(1) {
    margin: 10% auto;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section:nth-child(2) {
    margin: 10% auto;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 100%;
  }
  div.ten-thirty-one-exchange-info div.ten-thirty-one-exchange-info-section div.header-border-bottom {
    margin-bottom: 10%;
  }
}