div.learn-more {
  background-color: #DE1F2C;
  width: 100%;
  position: relative;
}

div.learn-more img {
  position: absolute;
  height: 100%;
  width: 100%;
}

div.learn-more div.learn-more-content {
  align-items: center;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2% 5%;
  position: relative;
  width: 100%;
  z-index: 2;
}

div.learn-more div.learn-more-content h3 {
  font-size: 2.25em;
  font-weight: 600;
  margin-bottom: 2%;
  text-align: center;
}

div.learn-more div.learn-more-content p {
  font-size: 1.5125em;
  font-weight: 300;
  margin-bottom: 2%;
  text-align: center;
}

div.learn-more div.learn-more-content a {
  background-color: #898888;
  color: #fff;
  font-size: 1.3125em;
  font-weight: 600;
  padding: 1% 3%;
  text-decoration: none;
}

div.learn-more div.learn-more-content a:visited {
  color: #fff;
}

@media screen and (max-width: 768px) {
  div.learn-more div.learn-more-content {
    padding: 3% 5%;
  }
  div.learn-more img {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.learn-more div.learn-more-content {
    padding: 7.5% 10%;
  }
  div.learn-more div.learn-more-content p {
    margin-bottom: 5%;
  }
}