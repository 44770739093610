div.advisory-services {
  align-items: space-evenly;
  background-color: #F2F1F1;
  display: flex;
  padding: 5% 10%;
}

div.advisory-services div {
  color: #414040;
  display: flex;
  flex-direction: column;
}

div.advisory-services div.advisory-services-left {
  margin-right: 10%;
  width: 50%;
}

div.advisory-services div h3 {
  color: #DE1F2C;
  font-size: 2.5em;
  font-weight: 600;
  line-height: 3rem;
  width: 70%;
}

div.advisory-services div div {
  border-bottom: 3px solid #414040;
  padding-top: 3%;
  margin-bottom: 5%;
  width: 17.5%;
}

div.advisory-services div p {
  font-size: 1.375rem;
  line-height: 2.25rem;
}

div.advisory-services div.advisory-services-right {
  margin-left: 10%;
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.advisory-services {
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
  }
  div.advisory-services div.advisory-services-left {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 10% auto;
    padding: 3% 5%;
    width: 75%;
  }
  div.advisory-services div.advisory-services-right {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto 10% auto;
    padding: 3% 5%;
    width: 75%;
  }
}

@media screen and (max-width: 425px) {
  div.advisory-services {
    text-align: left;
  }
  div.advisory-services div.advisory-services-left {
    align-items: flex-start;
    padding: 7.5% 0;
  }
  div.advisory-services div.advisory-services-right {
    align-items: flex-start;
    padding: 7.5% 0;
  }
  div.advisory-services div h3 {
    font-size: 2rem;
    width: 100%;
  }
  div.advisory-services div div {
    width: 40%;
  }
}