div.email-sign-up {
  align-items: center;
  background-color: #202020;
  color: #fff;
  display: flex;
  padding: 2% 5%;
  width: 100%;
}

div.email-sign-up img {
  height: 200px;
  margin: 0 5% 0 10%;
  width: 30%;
}

div.email-sign-up div.email-sign-up-content {
  display: flex;
  flex-direction: column;
  margin: 0 5%;
  width: 60%;
}

div.email-sign-up div.email-sign-up-content h2.email-sign-up-header {
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 1%;
}

div.email-sign-up div.email-sign-up-content p.email-sign-up-content-text {
  font-size: 1.5125rem;
  font-weight: 300;
  line-height: 29.04px;
  margin-bottom: 3%;
}

div.email-sign-up div.email-sign-up-content form.email-sign-up-form {
  align-items: stretch;
  display: flex;
  width: 85%;
}

div.email-sign-up div.email-sign-up-content form.email-sign-up-form input {
  border: none;
  font-size: 1.5125em;
  padding: 1% 0 1% 5%;
  width: 65%;
}

div.email-sign-up div.email-sign-up-content form.email-sign-up-form button {
  background-color: #e13936;
  border: none;
  color: #fff;
  font-size: 1.3125em;
  padding: 1% 2%;
  width: 35%;
}

div.email-sign-up div.email-sign-up-content form.email-sign-up-form button:hover {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  div.email-sign-up {
    padding: 3% 5%;
  }
  div.email-sign-up div.email-sign-up-content {
    margin: 0 2% 0 4%;
    width: 50%;
  }
  div.email-sign-up div.email-sign-up-content h2.email-sign-up-header {
    font-size: 2.25rem;
  }
  div.email-sign-up div.email-sign-up-content p.email-sign-up-content-text {
    font-size: 1.35rem;
  }
  div.email-sign-up div.email-sign-up-content form.email-sign-up-form {
    width: 100%;
  }
  div.email-sign-up div.email-sign-up-content form.email-sign-up-form input {
    font-size: 1.25rem;
    padding: 1% 2%;
  }
  div.email-sign-up div.email-sign-up-content form.email-sign-up-form button {
    font-size: 1.15rem;
    font-weight: 500;
  }
  div.email-sign-up img {
    margin: 0;
    width: 40%;
  }
}

@media screen and (max-width: 425px) {
  div.email-sign-up {
    flex-direction: column-reverse;
    padding: 7.5% 10%;
    text-align: center;
  }
  div.email-sign-up div.email-sign-up-content {
    align-items: center;
    margin-bottom: 10%;
    width: 100%;
  }
  div.email-sign-up div.email-sign-up-content h2.email-sign-up-header {
    font-size: 2.25rem;
    margin-bottom: 2%;
  }
  div.email-sign-up div.email-sign-up-content p.email-sign-up-content-text {
    font-size: 1.5rem;
    margin-bottom: 5%;
  }
  div.email-sign-up img {
    width: 100%;
  }
}