header {
  align-items: center;
  background-color: #fff;
  border-bottom: 2px solid #E4E2E2;
  display: flex;
  flex-direction: column;
}

div.header-sign-up {
  align-items: center;
  background-color: #DE1F2C;
  color: #fff;
  display: flex;
  font-size: 1.1rem;
  font-weight: normal;
  justify-content: center;
  padding: 4px 0;
  width: 100%;
}

div.header-sign-up a {
  color: #fff;
  margin-left: .5%;
}

div.header-main {
  align-items: center;
  display: flex;
  font-size: 0.9375rem;
  justify-content: center;
  padding: 1% 0;
  width: 100%;
}

div.header-main img.header-banner-hamburger {
  display: none;
}

div.header-main div.header-links img.header-links-close-nav {
  display: none;
}

div.header-main a.banner-logo-link {
  width: 12.5%;
  margin-left: 10%;
}

div.header-main a.banner-logo-link img.header-logo {
  height: 100%;
  width: 100%;
}

div.header-main a.banner-logo-link img.header-logo-mobile {
  display: none;
}

div.header-main div.header-links {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0 10% 0 2.5%;
  width: 75%;
}

div.header-main div.header-links div.header-contact-us {
  align-items: center;
  color: #898888;
  display: flex;
  font-weight: 600;
  margin-bottom: 1%;
  justify-content: flex-end;
  width: 100%;
}

div.header-main div.header-links div.header-contact-us a.header-contact-info {
  color: #898888;
  display: flex;
  font-weight: normal;
  margin-left: 4%;
  text-align: right;
  text-decoration: none;
}

div.header-main div.header-links div.header-contact-us a.header-contact-info:hover {
  color: #DE1F2C;
  cursor: pointer;
}

div.header-main div.header-links div.header-contact-us a.header-contact-info:hover, div.header-main div.header-links div.header-contact-us a.header-contact-info:hover:visited {
  color: #DE1F2C;
  cursor: pointer;
}

div.header-main div.header-links div.header-contact-us a.header-contact-info:visited {
  color: #898888;
}

div.header-main div.header-links div.header-contact-us a.header-contact-info i {
  height: 100%;
}

div.header-main div.header-links nav {
  align-items: center;
  border-top: 1px solid #E4E2E2;
  color: #898888;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding-top: 2.25rem;
  width: 100%;
}

div.header-main div.header-links nav a {
  color: #898888;
  text-decoration: none;
}

div.header-main div.header-links nav a:hover {
  color: #DE1F2C;
  cursor: pointer;
}

div.header-main div.header-links nav a:visited {
  color: #898888;
}

div.header-main div.header-links nav a:visited:hover {
  color: #DE1F2C;
}

@media screen and (max-width: 1225px) {
  div.header-main div.header-links nav a {
    font-size: .8rem;
  }
}

@media screen and (max-width: 1024px) {
  div.header-sign-up {
    font-size: .9em;
  }
  div.header-main a.banner-logo-link {
    width: 12.5%;
    margin-left: 10%;
  }
  div.header-main div.header-links nav {
    padding-top: 2em;
    margin-bottom: .75em;
  }
  div.header-main div.header-links nav a {
    font-size: .7em;
  }
}

@media screen and (max-width: 900px) {
  div.header-main {
    padding: 3% 0;
  }
  div.header-main div.header-links nav a {
    font-size: .6em;
  }
}

@media screen and (max-width: 768px) {
  div.header-main {
    justify-content: space-between;
  }
  div.header-main div.header-links {
    display: none;
    position: fixed;
  }
  div.header-main div.header-links.visible {
    background-color: #fff;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    margin: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 10000;
  }
  div.header-main div.header-links.visible img.header-links-close-nav {
    align-self: flex-end;
    display: block;
    margin: 5% 10% 0 0;
    width: 3%;
  }
  div.header-main div.header-links.visible nav {
    align-items: flex-start;
    border-top: none;
    flex-direction: column;
    justify-content: space-between;
    height: 50%;
    padding: 0;
    padding-left: 10%;
    width: 100%;
  }
  div.header-main div.header-links.visible nav a {
    font-size: 1.5rem;
  }
  div.header-main div.header-links.visible div.header-contact-us {
    align-items: flex-start;
    border-top: 1px solid #898888;
    flex-direction: column;
    font-size: 1.25rem;
    height: 30%;
    justify-content: space-between;
    margin-top: 2%;
    padding-left: 10%;
    padding-top: 2%;
  }
  div.header-main div.header-links div.header-contact-us a.header-contact-info {
    margin-left: 0;
    width: 50%;
  }
  div.header-main a.banner-logo-link img.header-logo {
    display: none;
  }
  div.header-main a.banner-logo-link img.header-logo-mobile {
    display: block;
    width: 100%;
  }
  div.header-main img.header-banner-hamburger {
    display: block;
    margin: 0 5%;
    width: 15%;
  }
  div.header-main a.banner-logo-link {
    margin: 0 5%;
    width: 30%;
  }
  div.header-main div.header-mobile-contact a:visited {
    color: #898888;
  }
}

@media screen and (max-width: 425px) {
  div.header-sign-up {
    display: block;
    font-size: 1.25rem;
    text-align: center;
  }
  div.header-sign-up a {
    width: 100%;
  }
  div.header-main div.header-links.visible img.header-links-close-nav {
    width: 5%;
    margin-bottom: 5%;
  }
  div.header-main div.header-links div.header-contact-us a.header-contact-info {
    margin-left: 0;
    width: 50%;
  }
  div.header-main div.header-links div.header-contact-us a.header-contact-info p {
    margin-left: 5%;
  }
  div.header-main a.banner-logo-link img.header-logo {
    display: none;
  }
  div.header-main a.banner-logo-link img.header-logo-mobile {
    display: block;
    width: 100%;
  }
  div.header-main img.header-banner-hamburger {
    display: block;
    margin: 0 2%;
    width: 25%;
  }
  div.header-main a.banner-logo-link {
    margin: 0 5%;
    width: 50%;
  }
  div.header-main div.header-mobile-contact a:visited {
    color: #898888;
  }
}

@media screen and (max-width: 320px) {
  div.header-main div.header-links div.header-contact-us a.header-contact-info p {
    font-size: 1rem;
  }
}