div.admin-job-positions {
  background-color: #fff;
  display: flex;
  margin: 3% 5%;
}

div.admin-job-positions div.admin-job-positions-types {
  color: #404041;
  margin: 3% 0% 5% 7.5%;
  width: 70%;
}

div.admin-job-positions div.admin-job-positions-types h3.admin-job-positions-types-header {
  font-size: 2.833125rem;
  font-weight: 600;
}

div.admin-job-positions div.admin-job-positions-types h3.admin-job-positions-types-header a{
  border: 3px solid green;
  color: green;
  font-size: 1.5rem;
  padding: 1%;
  text-decoration: none;
}

div.admin-job-positions div.admin-job-positions-types div.admin-job-positions-types-header-underline {
  border-bottom: 3px solid #404041;
  margin-bottom: 5%;
  padding-top: 1%;
  width: 10%;
}

div.admin-job-positions div.admin-job-positions-types div.admin-job-positions-types-type {
  border-bottom: 1px solid #D4D7D8;
  margin-bottom: 5%;
  padding-bottom: 2%;
}

div.admin-job-positions div.admin-job-positions-types div.admin-job-positions-types-type h4 {
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 1.5%;
}

div.admin-job-positions div.admin-job-positions-types div.admin-job-positions-types-type p {
  color: #6F6F6F;
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-bottom: 1.5%;
}

div.admin-job-positions div.admin-job-positions-types div.admin-job-positions-types-type a {
  color: #DE1F2C;
  font-size: 1.375rem;
  font-weight: 600;
  text-decoration: none;
}