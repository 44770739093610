div.investment-sales-banner {
  align-items: center;
  background-color: #F2F1F1;
  background-image: url("../../../assets/images/multiple-pages/1031-exchange-background.jpg");
  background-repeat: repeat-y;
  display: flex;
  justify-content: space-evenly;
}

div.investment-sales-banner div.investment-sales-banner-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
  width: 50%;
}

div.investment-sales-banner div.investment-sales-banner-content img {
  border-bottom: 3px solid #858484;
  margin-bottom: 1%;
  padding-bottom: 1%;
}

div.investment-sales-banner div.investment-sales-banner-content h3 {
  color: #DE1F2C;
  font-size: 3.98625em;
  font-weight: 600;
  line-height: 1em;
}

div.investment-sales-banner div.investment-sales-banner-content p {
  color: #6F6F6F;
  font-size: 1.375rem;
  line-height: 1.5em;
}

div.investment-sales-banner img.investment-sales-banner-image {
  height: 100%;
  margin: 2% 2%;
  width: 32.5%;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  div.investment-sales-banner {
    background-size: contain;
    flex-direction: column;
    padding: 3% 5%;
    text-align: center;
  }
  div.investment-sales-banner div.investment-sales-banner-content {
    align-items: center;
    width: 90%;
  }
  div.investment-sales-banner div.investment-sales-banner-content h3 {
    font-size: 3rem;
  }
  div.investment-sales-banner div.investment-sales-banner-content img {
    width: 15%;
    margin-bottom: 3%;
  }
  div.investment-sales-banner img.investment-sales-banner-image {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  div.investment-sales-banner {
    padding: 7.5% 10%;
  }
  div.investment-sales-banner div.investment-sales-banner-content img {
    width: 25%;
  }
  div.investment-sales-banner img.investment-sales-banner-image {
    width: 100%;
  }
}