div.asset-types {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
}

div.asset-types h3.asset-types-header {
  color: #DE1F2C;
  font-size: 2.5em;
  font-weight: 600;
  text-align: center;
}

div.asset-types div.asset-types-header-underline {
  border-bottom: 3px solid #858484;
  margin-bottom: 1%;
  padding-top: 1%;
  width: 7.5%;
}

div.asset-types p.asset-types-header-slogan {
  color: #6F6F6F;
  font-size: 1.0625em;
  margin-bottom: 5%;
}

div.asset-types div.asset-types-content {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

div.asset-types div.asset-types-content div.asset-types-content-type {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

div.asset-types div.asset-types-content div.asset-types-content-type img.asset-types-content-image {
  height: 20vh;
  margin-bottom: 5%;
}

div.asset-types div.asset-types-content div.asset-types-content-type h4.asset-types-content-header {
  color: #DE1F2C;
  font-size: 1.6875em;
  font-weight: 500;
  text-align: center;
}

div.asset-types div.asset-types-content div.asset-types-content-type p.asset-types-content-description {
  color: #6F6F6F;
  font-size: 1.0625em;
  line-height: 27px;
  text-align: center;
  width: 85%;
}

@media screen and (max-width: 1024px) {
  div.asset-types div.asset-types-content div.asset-types-content-type img.asset-types-content-image {
    height: 15vh;
  }
}

@media screen and (max-width: 768px) {
  div.asset-types div.asset-types-content div.asset-types-content-type h4.asset-types-content-header {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 425px) {
  div.asset-types div.asset-types-content {
    flex-direction: column;
  }
  div.asset-types p.asset-types-header-slogan {
    margin-bottom: 20%;
  }
  div.asset-types div.asset-types-content div.asset-types-content-type {
    margin-bottom: 20%;
  }
  div.asset-types div.asset-types-content div.asset-types-content-type h4.asset-types-content-header {
    text-align: center;
  }
}