div.investment-sales-honesty {
  display: flex;
  justify-content: space-evenly;
  padding: 10%;
}

div.investment-sales-honesty div.investment-sales-honesty-left {
  margin-right: 10%;
  width: 50%;
}

div.investment-sales-honesty div h3 {
  font-size: 2.5em;
  font-weight: 600;
}

div.investment-sales-honesty div div {
  border-bottom: 3px solid #000;
  margin-bottom: 5%;
  width: 17.5%;
}

div.investment-sales-honesty div p {
  font-size: 1.375em;
  line-height: 1.45em;
}

div.investment-sales-honesty div.investment-sales-honesty-right {
  margin-left: 10%;
  width: 50%;
}

div.investment-sales-honesty div h3 {
  color: #DE1F2C;
}

@media screen and (max-width: 768px) {
  div.investment-sales-honesty {
    flex-direction: column;
    padding: 0;
    text-align: center;
    width: 100%;
  }
  div.investment-sales-honesty div.investment-sales-honesty-left {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 10% auto;
    padding: 3% 5%;
    width: 75%;
  }
  div.investment-sales-honesty div.investment-sales-honesty-right {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto 10% auto;
    padding: 3% 5%;
    width: 75%;
  }
}

@media screen and (max-width: 425px) {
  div.investment-sales-honesty {
    text-align: left;
  }
  div.investment-sales-honesty div.investment-sales-honesty-left {
    align-items: flex-start;
    padding: 7.5% 0;
  }
  div.investment-sales-honesty div.investment-sales-honesty-right {
    align-items: flex-start;
    padding: 7.5% 0;
  }
  div.investment-sales-honesty div img {
    margin-bottom: 3%;
  }
  div.investment-sales-honesty div h3 {
    font-size: 2rem;
  }
  div.investment-sales-honesty div div {
    width: 40%;
  }
}