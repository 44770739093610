div.ten-thirty-one-exchange-form {
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 0 20% 0 20%;
  position: relative;
}

div.ten-thirty-one-exchange-form p.ten-thirty-one-exchange-form-text {
  font-size: 2.5em;
  font-weight: 300;
  width: 60%;
  z-index: 2;
}

div.ten-thirty-one-exchange-form p.ten-thirty-one-exchange-form-text span {
  font-weight: 600;
}

div.ten-thirty-one-exchange-form form {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.375em;
  justify-content: space-between;
  margin-top: 5%;
  width: 40%;
  z-index: 2;
}

div.ten-thirty-one-exchange-form form .ten-thirty-one-exchange-form-input-name {
  width: 45%;
}

div.ten-thirty-one-exchange-form form label {
  width: 100%;
}

div.ten-thirty-one-exchange-form form input {
  border: none;
  font-size: 1.25em;
  margin-bottom: 5%;
  width: 100%;
}

div.ten-thirty-one-exchange-form form input:focus, div.ten-thirty-one-exchange-form form textarea:focus {
  outline: none;
}

div.ten-thirty-one-exchange-form form textarea {
  border: none;
  font-size: 1.25em;
  height: 3em;
  margin-bottom: 5%;
  resize: none;
  width: 100%;
}

div.ten-thirty-one-exchange-form form button, p.ten-thirty-one-exchange-form-button {
  background-color: #202020;
  border: none;
  color: #fff;
  font-size: 1.181875rem;
  font-weight: 600;
  padding: 3% 4%;
  margin-bottom: 5%;
  text-align: center;
  width: 50%;
}

div.ten-thirty-one-exchange-form img.ten-thirty-one-exchange-form-background {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
}

@media screen and (max-width: 768px) {
  div.ten-thirty-one-exchange-form {
    background-color: #DE1F2C;
    flex-direction: column;
    padding: 3% 5%;
  }
  div.ten-thirty-one-exchange-form p.ten-thirty-one-exchange-form-text {
    margin: 5% auto;
    width: 75%;
  }
  div.ten-thirty-one-exchange-form form {
    margin: 2.5% auto;
    width: 75%;
  }
  div.ten-thirty-one-exchange-form form button {
    width: 35%;
  }
  div.ten-thirty-one-exchange-form img.ten-thirty-one-exchange-form-background {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.ten-thirty-one-exchange-form {
    padding: 7.5% 0;
  }
  div.ten-thirty-one-exchange-form form button, p.ten-thirty-one-exchange-form-button {
    width: 50%;
  }
}