div.careers-banner {
  background-image: url("../../../assets/images/careers/careers-banner-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  padding: 6% 27.5% 6.5% 27.5%;
  text-align: center;
}

div.careers-banner h3 {
  font-size: 2.833125rem;
  font-weight: 600;
  margin-bottom: 2%;
}

div.careers-banner p {
  font-size: 1.5625rem;
}

@media screen and (max-width: 768px) {
  div.careers-banner {
    padding: 10%;
  }
}

@media screen and (max-width: 425px) {
  div.careers-banner {
    padding: 7.5%;
  }
}