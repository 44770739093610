form.property-evaluation-signup {
  background-color: #ffffff;
  border: 1px solid #000000;
  display: none;
  margin: 0 37.5%;
  position: fixed;
  top: 15vh;
  width: 25%;
  z-index: 1000;
}

form.visible {
  align-items: center;
  display: flex;
  flex-direction: column;
}

form.property-evaluation-signup img {
  align-self: flex-end;
  width: 5%;
  margin: 5% 5% 5% 0;
}

form.property-evaluation-signup img:hover {
  cursor: pointer;
}

form.property-evaluation-signup input {
  background-color: rgb(224, 224, 224);
  border: none;
  font-size: 1.2rem;
  margin-bottom: 20px;
  padding: 10px 15px 10px 5px;
  width: 90%;
}

form.property-evaluation-signup input::placeholder {
  font-size: 1.2rem;
}

form.property-evaluation-signup button {
  background-color: #DE1F2C;
  border: none;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 20px;
  padding: 10px 15px 10px 5px;
  text-align: center;
  width: 90%;
}

@media screen and (max-width: 768px) {
  form.property-evaluation-signup {
    margin: 0 25%;
    top: 5vh;
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  form.property-evaluation-signup {
    margin: 0 12.5%;
    width: 75%;
  }
}