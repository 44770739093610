div.property-management-tech {
  display: flex;
  background-color: #DE1F2C;
}

div.property-management-tech div.property-management-tech-content {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10% 10%;
  width: 50%;
}

div.property-management-tech div.property-management-tech-content h3.property-management-tech-content-header {
  font-size: 2.5em;
  font-weight: 600;
}

div.property-management-tech div.property-management-tech-content div.property-management-tech-content-header-underline {
  border-bottom: 3px solid #fff;
  width: 20%;
  padding-top: 2%;
  margin-bottom: 5%;
}

div.property-management-tech div.property-management-tech-content p.property-management-tech-content-text {
  font-size: 1.375em;
  line-height: 2.25rem;
}

div.property-management-tech img.property-management-tech-image {
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.property-management-tech {
    flex-direction: column;
  }
  div.property-management-tech div.property-management-tech-content {
    align-items: center;
    margin: 10% auto;
    padding: 3% 5%;
    text-align: center;
    width: 75%;
  }
  div.property-management-tech img.property-management-tech-image {
    height: 75vh;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  div.property-management-tech div.property-management-tech-content {
    align-items: flex-start;
    padding: 7.5% 0;
    text-align: left;
  }
  div.property-management-tech div.property-management-tech-content h3.property-management-tech-content-header {
    font-size: 2rem;
  }
  div.property-management-tech div.property-management-tech-content div.property-management-tech-content-header-underline {
    width: 30%;
  }
  div.property-management-tech img.property-management-tech-image {
    height: 45vh;
  }
}