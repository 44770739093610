div.news-and-insights-posts {
  align-items: center;
  background-image: linear-gradient(#F2F1F1 50%, #DE1F2C 50%);
  display: flex;
  flex-direction: column;
}

div.news-and-insights-posts div.news-and-insights-posts-header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1%;
  width: 100%;
}

div.news-and-insights-posts div.news-and-insights-posts-header div.news-and-insights-posts-header-sideline {
  border-bottom: 3px solid #858484;
  margin: .5%;
  width: 5%;
}

div.news-and-insights-posts div.news-and-insights-posts-header h3.news-and-insights-posts-header-text {
  color: #DE1F2C;
  font-size: 2.833125rem;
  font-weight: 600;
}

div.news-and-insights-posts div.news-and-insights-posts-content {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto 5% auto;
  width: 95%;
}

div.news-and-insights-posts div.news-and-insights-posts-content div {
  display: flex;
  flex-wrap: wrap;
}

div.news-and-insights-posts div.news-and-insights-posts-content div div.news-and-insights-posts-content-post {
  align-items: flex-start;
  border-bottom: 1.5px solid #E4E6E6;
  display: flex;
  flex-direction: column;
  margin: 3% 2% 0 2%;
  padding-bottom: 1%;
  width: 29%;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post img {
  width: 100%;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post a.news-and-insights-posts-content-post-header {
  color: #7D464A;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2%;
  margin-top: 5%;
  text-decoration: none;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post a.news-and-insights-posts-content-post-header:hover {
  text-decoration: underline;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post a.news-and-insights-posts-content-post-header:visited {
  color: #7D464A;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post p.news-and-insights-posts-content-post-text {
  color: #6F6F6F;
  font-size: 1.375rem;
  line-height: 1.875rem;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post a.read-more {
  color: #6F6F6F;
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 5%;
  text-decoration: none;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post a.read-more:hover {
  text-decoration: underline;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post a.read-more:visited {
  color: #6F6F6F;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post div.news-and-insights-posts-content-post-time-and-author {
  color: #6F6F6F;
  display: flex;
  font-size: 1.1875rem;
  font-weight: 300;
  justify-content: space-between;
  width: 65%;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post div.news-and-insights-posts-content-post-time-and-author p.bullet {
  color: #414040;
  font-weight: 600;
}

div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post div.news-and-insights-posts-content-post-time-and-author p span {
  font-style: italic;
}

div.news-and-insights-posts div.news-and-insights-posts-content button {
  background-color: #DE1F2C;
  border: none;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  margin: 3%;
  padding: .5% 1%;
}

div.news-and-insights-posts div.news-and-insights-posts-content button.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  div.news-and-insights-posts div.news-and-insights-posts-content div div.news-and-insights-posts-content-post {
    width: 43%;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div {
    justify-content: space-between;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post div.news-and-insights-posts-content-post-time-and-author {
    width: 100%;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content button {
    padding: 1% 2%;
  }
}

@media screen and (max-width: 425px) {
  div.news-and-insights-posts {
    background-image: none;
    background-color: #F2F1F1;
  }
  div.news-and-insights-posts div.news-and-insights-posts-header h3.news-and-insights-posts-header-text {
    font-size: 2rem;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content {
    background-color: #F2F1F1;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div {
    justify-content: center;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div div.news-and-insights-posts-content-post {
    background-color: #fff;
    margin-bottom: 5%;
    width: 93%;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div div.news-and-insights-posts-content-post>* {
    padding: 1% 10%;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div div.news-and-insights-posts-content-post img {
    padding: 0;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content div.news-and-insights-posts-content-post div.news-and-insights-posts-content-post-time-and-author {
    margin-bottom: 5%;
  }
  div.news-and-insights-posts div.news-and-insights-posts-content button {
    padding: 2% 5%;
  }
}