div.leasing-form {
  background-color: #DE1F2C;
  color: #fff;
  display: flex;
}

div.leasing-form div.leasing-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 5%;
  width: 50%;
}

div.leasing-form div.leasing-form-content h3.leasing-form-content-header {
  font-size: 2.5em;
  font-weight: 300;
  line-height: 3rem;
  margin-bottom: 5%;
}

div.leasing-form div.leasing-form-content h3.leasing-form-content-header span {
  font-weight: 600;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.375em;
  justify-content: space-between;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form label {
  margin-bottom: 1%;
  width: 100%;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form input {
  border: none;
  font-size: 1.375rem;
  height: 2.6875rem;
  margin-bottom: 3%;
  padding-left: 1%;
  width: 100%;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form .leasing-form-content-form-input-name {
  width: 45%;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form textarea {
  border: none;
  font-size: 1.375rem;
  height: 5.5rem;
  margin-bottom: 3%;
  padding-left: 1%;
  resize: none;
  width: 100%;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form input:focus, div.leasing-form div.leasing-form-content form.leasing-form-content-form textarea:focus {
  outline: none;
}

div.leasing-form div.leasing-form-content form.leasing-form-content-form button, p.leasing-form-content-form-button {
  background-color: #898888;
  border: none;
  color: #fff;
  font-size: 1.18125rem;
  font-weight: 600;
  padding: 1.5% 1%;
  text-align: center;
  width: 35%;
}

div.leasing-form div.leasing-form-image {
  background-image: url("../../../assets/images/leasing/retail-space-for-lease.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.leasing-form {
    flex-direction: column;
  }
  div.leasing-form div.leasing-form-content {
    margin: 0 auto;
    padding: 3% 5%;
    width: 75%;
  }
  div.leasing-form img.leasing-form-image {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.leasing-form div.leasing-form-content {
    padding: 7.5% 0;
  }
  div.leasing-form div.leasing-form-content h3.leasing-form-content-header {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 5%;
    text-align: center;
  }
  div.leasing-form div.leasing-form-content form.leasing-form-content-form button, p.leasing-form-content-form-button {
    font-size: 1rem;
    margin-top: 3%;
    padding: 2% 3%;
    width: 40%;
  }
}