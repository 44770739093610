div.property-types {
  display: flex;
  flex-wrap: wrap;
}

div.property-types div.property-type-container {
  position: relative;
  width: 50%;
}

div.property-types div.property-type-container div.property-type-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  height: 50vh;
  width: 100%;
}

div.property-types div.property-type-container:nth-child(1) div.property-type-background-image {
  background-image: url("../../../assets/images/1031-exchange/1031-single-tenant.jpeg");
}

div.property-types div.property-type-container:nth-child(2) div.property-type-background-image {
  background-image: url("../../../assets/images/1031-exchange/1031-office-building.jpeg");
}

div.property-types div.property-type-container:nth-child(3) div.property-type-background-image {
  background-image: url("../../../assets/images/1031-exchange/1031-new-development.jpg");
}

div.property-types div.property-type-container:nth-child(4) div.property-type-background-image {
  background-image: url("../../../assets/images/1031-exchange/1031-shopping-center.jpg");
}

div.property-types div.property-type-container p.property-type {
  background-color: #DE1F2C;
  color: #fff;
  font-size: 1.375em;
  padding: .5% 1%;
  position: absolute;
  text-align: center;
  top: 10%;
  width: 45%;
}

@media screen and (max-width: 768px) {
  div.property-types div.property-type-container p.property-type {
    padding: .5% 3%;
    width: 60%;
  }
  div.property-types div.property-type-container p.property-type span {
    display: none
  }
}

@media screen and (max-width: 425px) {
  div.property-types {
    flex-direction: column;
  }
  div.property-types div.property-type-container {
    display: flex;
    height: 50vh;
    width: 100%;
  }
}