div.investment-sales-tech {
  background-color: #414040;
  color: #fff;
  display: flex;
}

div.investment-sales-tech div.investment-sales-tech-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 5%;
  width: 50%;
}

div.investment-sales-tech div.investment-sales-tech-content h3 {
  font-size: 2.5em;
  font-weight: 600;
}

div.investment-sales-tech div.investment-sales-tech-content div {
  border-bottom: 3px solid #fff;
  margin-bottom: 5%;
  width: 13%;
}

div.investment-sales-tech div.investment-sales-tech-content p {
  font-size: 1.375em;
  line-height: 1.45em;
  width: 85%;
}

div.investment-sales-tech img.investment-sales-tech-image {
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.investment-sales-tech {
    flex-direction: column;
  }
  div.investment-sales-tech div.investment-sales-tech-content {
    align-items: center;
    margin: 10% auto;
    padding: 3.5% 5%;
    text-align: center;
    width: 75%;
  }
  div.investment-sales-tech img.investment-sales-tech-image {
    width: 100%;
  }
  div.investment-sales-tech div.investment-sales-tech-content p {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  div.investment-sales-tech div.investment-sales-tech-content {
    align-items: flex-start;
    padding: 7.5% 0;
    text-align: left;
  }
  div.investment-sales-tech div.investment-sales-tech-content img {
    margin-bottom: 3%;
  }
  div.investment-sales-tech div.investment-sales-tech-content h3 {
    font-size: 2rem;
  }
  div.investment-sales-tech div.investment-sales-tech-content div {
    width: 40%;
  }
}