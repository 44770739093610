div.property-management-services {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

div.property-management-services div.property-management-services-header {
  background-color: #747474;
  color: #fff;
  width: 100%;
}

div.property-management-services div.property-management-services-header:nth-child(12) {
  background-color: #DE1F2C;
}

div.property-management-services div.property-management-services-header h3.property-management-services-header-text {
  font-size: 2.5em;
  font-weight: 600;
  margin-top: 1%;
}

div.property-management-services div.property-management-services-header div.property-management-services-header-underline {
  border-bottom: 3px solid #fff;
  margin: .5% auto 2% auto;
  width: 5%;
}

div.property-management-services div.property-management-services-row-content.desktop-odd {
  background-color: #F2F1F1;
}

div.property-management-services div.property-management-services-row-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 3% 5%;
  width: 33.3333333333%;
}

div.property-management-services div.property-management-services-row-content:nth-child(11) {
  display: none;
}

div.property-management-services div.property-management-services-row-content img.property-management-services-row-content-image {
  height: 35%;
  margin-bottom: 5%;
}

div.property-management-services div.property-management-services-row-content h4.property-management-services-row-content-header {
  color: #DE1F2C;
  font-size: 1.6875em;
  font-weight: 500;
  margin-bottom: 5%;
}

div.property-management-services div.property-management-services-row-content p.property-management-services-row-content-description {
  color: #6F6F6F;
  font-size: 1.0625em;
}

@media screen and (max-width: 768px) {
  div.property-management-services div.property-management-services-row-content {
    width: 50%;
  }
  div.property-management-services div.property-management-services-row-content.desktop-odd {
    background-color: #fff;
  }
  div.property-management-services div.property-management-services-row-content.responsive-odd {
    background-color: #F2F1F1;
  }
  div.property-management-services div.property-management-services-row-content:nth-child(11) {
    display: flex;
  }
  div.property-management-services div.property-management-services-row-content h4.property-management-services-row-content-header {
    margin-bottom: 1%;
  }
}

@media screen and (max-width: 425px) {
  div.property-management-services div.property-management-services-row-content {
    padding: 10% 5%;
  }
  div.property-management-services div.property-management-services-row-content img.property-management-services-row-content-image {
    width: 30%;
    height: 25%;
  }
  div.property-management-services div.property-management-services-row-content h4.property-management-services-row-content-header {
    font-size: 1.25rem;
    margin-bottom: 5%;
  }
}