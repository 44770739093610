div.admin-main {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
div.admin-main div.admin-signed-in {
  display: flex;
  flex-direction: column;
  width: 100%;
}

div.admin-main div.admin-signed-in nav {
  align-items: flex-start;
  background-color: #F2F1F1;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3%;
  width: 100%;
}

div.admin-main div.admin-signed-in nav a, button.sign-out {
  background-color: #F2F1F1;
  border: none;
  color: #000;
  font-size: 1.25rem;
  text-align: center;
  text-decoration: none;
  width: 20%;
}

div.admin-main div.admin-signed-in nav a:visited {
  color: #000;
}

div.admin-main div.admin-signed-in nav a:hover, button.sign-out:hover {
  color: #DE1F2C;
}

div.admin-main form.admin-login-form {
  align-self: center;
  margin: 5%;
}