div.leasing-banner {
  align-items: center;
  background-color: #F2F1F1;
  background-image: url("../../../assets/images/multiple-pages/1031-exchange-background.jpg");
  background-repeat: repeat-y;
  display: flex;
  justify-content: space-evenly;
}

div.leasing-banner img.leasing-banner-background-image {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 1;
}

div.leasing-banner div.leasing-banner-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 5% 0;
  width: 35%;
  z-index: 2;
}

div.leasing-banner div.leasing-banner-content img {
  border-bottom: 3px solid #858484;
  margin-bottom: 3%;
  padding-bottom: 3%;
}

div.leasing-banner div.leasing-banner-content h3 {
  color: #DE1F2C;
  font-size: 3.98625em;
  font-weight: 600;
  line-height: 3.4375rem;
}

div.leasing-banner div.leasing-banner-content p {
  color: #6F6F6F;
  font-size: 1.375em;
  line-height: 2.25rem;
  width: 85%;
}

div.leasing-banner img.leasing-banner-image {
  height: 100%;
  margin: 2% 0;
  width: 45%;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  div.leasing-banner {
    background-size: contain;
    flex-direction: column;
    padding: 3% 5%;
    text-align: center;
  }
  div.leasing-banner div.leasing-banner-content {
    align-items: center;
    width: 90%;
  }
  div.leasing-banner div.leasing-banner-content h3 {
    font-size: 3rem;
  }
  div.leasing-banner div.leasing-banner-content img {
    width: 12.5%;
    margin-bottom: 4%;
    padding-bottom: 4%;
  }
  div.leasing-banner div.leasing-banner-content p {
    width: 100%;
  }
  div.leasing-banner img.leasing-banner-image {
    width: 75%;
  }
}

@media screen and (max-width: 425px) {
  div.leasing-banner {
    padding: 7.5% 0;
  }
  div.leasing-banner div.leasing-banner-content img {
    width: 15%;
  }
}