div.property-management-banner {
  align-items: center;
  background-color: #F2F1F1;
  background-image: url("../../../assets/images/multiple-pages/1031-exchange-background.jpg");
  background-repeat: repeat-y;
  display: flex;
  padding: 2.5% 10%;
}

div.property-management-banner div.property-management-banner-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  width: 50%;
  z-index: 2;
}

div.property-management-banner div.property-management-banner-content img {
  border-bottom: 3px solid #858484;
  margin-bottom: 1%;
  padding-bottom: 1%;
}

div.property-management-banner div.property-management-banner-content h3 {
  color: #DE1F2C;
  font-size: 3.98625em;
  font-weight: 600;
  line-height: 1em;
}

div.property-management-banner div.property-management-banner-content p {
  color: #6F6F6F;
  font-size: 1.375em;
  line-height: 1.5em;
  width: 85%;
}

div.property-management-banner form#property-management-banner-form {
  align-items: flex-start;
  background-color: #DE1F2C;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 2%;
  padding: 5% 5%;
  width: 50%;
  z-index: 2;
}

div.property-management-banner form#property-management-banner-form h4 {
  font-size: 2.5em;
  font-weight: 600;
  margin-bottom: 4%;
  width: 100%;
}

div.property-management-banner form#property-management-banner-form label {
  font-size: 1.375em;
  width: 100%;
}

div.property-management-banner form#property-management-banner-form input {
  border: none;
  font-size: 1.375em;
  height: 2.6875rem;
  margin-bottom: 2%;
  padding-left: 1%;
  width: 100%;
}

div.property-management-banner form#property-management-banner-form .property-management-banner-form-input-name {
  width: 45%;
}

div.property-management-banner form#property-management-banner-form input:focus {
  outline: none;
}

div.property-management-banner form#property-management-banner-form button, p.property-management-banner-form-button {
  background-color: #898888;
  border: none;
  color: #fff;
  font-size: 1.181875em;
  font-weight: 600;
  margin-top: 3%;
  padding: 1.5% 3%;
  text-align: center;
  width: 35%;
}

@media screen and (max-width: 768px) {
  div.property-management-banner {
    background-size: contain;
    flex-direction: column;
    padding: 3% 5%;
  }
  div.property-management-banner div.property-management-banner-content {
    align-items: center;
    text-align: center;
    width: 90%;
  }
  div.property-management-banner div.property-management-banner-content img {
    margin: 3% 0;
  }
  div.property-management-banner div.property-management-banner-content h3 {
    font-size: 3rem;
  }
  div.property-management-banner form#property-management-banner-form {
    margin: 10%;
    width: 75%;
  }
  div.property-management-banner form#property-management-banner-form button {
    padding: 2% 4%;
  }
}

@media screen and (max-width: 425px) {
  div.property-management-banner {
    padding: 7.5% 0;
  }
  div.property-management-banner form#property-management-banner-form {
    width: 90%;
  }
  div.property-management-banner form#property-management-banner-form h4 {
    font-size: 2.2rem;
    text-align: center;
  }
  div.property-management-banner form#property-management-banner-form button, p.property-management-banner-form-button {
    width: 40%;
  }
}