div.advisory-banner {
  align-items: center;
  background-color: #F2F1F1;
  background-image: url("../../../assets/images/advisory/advisory-background.jpg");
  background-repeat: repeat-y;
  background-size: cover;
  display: flex;
  padding: 5% 0;
}

div.advisory-banner div.advisory-banner-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin: 0 10% 0 5%;
}

div.advisory-banner div.advisory-banner-content img {
  border-bottom: 3px solid #858484;
  margin-bottom: 3%;
  padding-bottom: 3%;
}

div.advisory-banner div.advisory-banner-content h3 {
  color: #DE1F2C;
  font-size: 3.98625rem;
  font-weight: 300;
  line-height: 3.4375rem;
  margin-bottom: 2%;
}

div.advisory-banner div.advisory-banner-content h3 span {
  font-weight: 600;
}

div.advisory-banner div.advisory-banner-content p {
  color: #6F6F6F;
  font-size: 1.375rem;
  line-height: 2rem;
  margin-bottom: 3%;
}

div.advisory-banner div.advisory-banner-content a {
  color: #fff;
  background-color: #DE1F2C;
  font-size: 1.181875rem;
  font-weight: 600;
  padding: 1% 5%;
  text-decoration: none;
}

div.advisory-banner div.advisory-banner-content a:visited {
  color: #fff
}

div.advisory-banner img.advisory-banner-image {
  height: 100%;
  margin: 0% 5% 0% 10%;
  width: 40%;
}

@media screen and (max-width: 768px) {
  div.advisory-banner {
    background-color: #F2F1F1;
    background-image: url("../../../assets/images/multiple-pages/1031-exchange-background.jpg");
    background-size: contain;
    flex-direction: column-reverse;
    padding: 10% 5%;
    text-align: center;
  }
  div.advisory-banner div.advisory-banner-content {
    align-items: center;
    margin: 0 auto 10% auto;
    width: 90%;
  }
  div.advisory-banner div.advisory-banner-content h3 {
    font-size: 3rem;
  }
  div.advisory-banner img.advisory-banner-image {
    width: 50%;
  }
}

@media screen and (max-width: 425px) {
  div.advisory-banner {
    padding: 7.5% 10%;
  }
  div.advisory-banner div.advisory-banner-content {
    margin-bottom: 15%;
  }
  div.advisory-banner div.advisory-banner-content img {
    width: 25%;
  }
  div.advisory-banner img.advisory-banner-image {
    width: 100%;
  }
}