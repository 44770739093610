div.banner {
  align-items: center;
  background-image: url("../../../assets/images/multiple-pages/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 30%;
  text-align: center;
  width: 100%;
}

div.banner h1 {
  color: #DE1F2C;
  font-size: 3.98625rem;
  font-weight: 600;
  margin-bottom: 2.5%;
}

div.banner p {
  color: #6F6F6F;
  font-size: 1.375em;
  margin-bottom: 5%;
  width: 80%
}

div.banner a {
  background-color: #DE1F2C;
  color: #fff;
  font-size: 1.3125em;
  font-weight: 600;
  padding: 1% 3%;
  text-decoration: none;
}

div.banner a:visited {
  color: #fff;
}

@media screen and (max-width: 1075px) {
  div.banner {
    padding: 6% 10%;
  }
}

@media screen and (max-width: 768px) {
  div.banner {
    background-position: center;
    padding: 6% 15%;
  }
  div.banner p {
    color: #000;
    font-size: 1.5rem;
    line-height: 2rem;
    width: 90%;
  }
}

@media screen and (max-width: 425px) {
  div.banner {
    background-size: 200% 600px;
    padding: 20% 3%;
  }
  div.banner h1 {
    font-size: 2.9rem;
  }
  div.banner p {
    font-size: 1.35rem;
  }
}