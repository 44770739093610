div.job-positions {
  background-color: #fff;
  display: flex;
  margin: 3% 5%;
}

div.job-positions div.job-positions-types {
  color: #404041;
  margin: 3% 0% 5% 7.5%;
  width: 70%;
}

div.job-positions div.job-positions-types h3.job-positions-types-header {
  font-size: 2.833125rem;
  font-weight: 600;
}

div.job-positions div.job-positions-types div.job-positions-types-header-underline {
  border-bottom: 3px solid #404041;
  margin-bottom: 5%;
  padding-top: 1%;
  width: 10%;
}

div.job-positions div.job-positions-types div.job-positions-types-type {
  border-bottom: 1px solid #D4D7D8;
  margin-bottom: 5%;
  padding-bottom: 2%;
}

div.job-positions div.job-positions-types div.job-positions-types-type h4 {
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 1.5%;
}

div.job-positions div.job-positions-types div.job-positions-types-type p {
  color: #6F6F6F;
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin-bottom: 1.5%;
}

div.job-positions div.job-positions-types div.job-positions-types-type a {
  color: #DE1F2C;
  font-size: 1.375rem;
  font-weight: 600;
  text-decoration: none;
}

div.job-positions div.job-positions-sign-up {
  background-color: #DE1F2C;
  color: #fff;
  margin: 10% 3% auto 4%;
  padding: 4% 2%;
  width: 30%;
}

div.job-positions div.job-positions-sign-up h3 {
  font-size: 2.4375rem;
  font-weight: 600;
  margin-bottom: 10%;
}

div.job-positions div.job-positions-sign-up p {
  font-size: 1.5125rem;
  font-weight: 300;
  line-height: 1.815rem;
  margin-bottom: 10%;
}

div.job-positions div.job-positions-sign-up form input {
  border: none;
  font-size: 1.3125rem;
  padding: 1.5%;
}

div.job-positions div.job-positions-sign-up form input::placeholder {
  color: #202020;
  font-size: 1.5125rem;
  font-weight: 300;
  padding-left: 1%;
}

div.job-positions div.job-positions-sign-up form button {
  background-color: #414040;
  border: none;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  padding: 1.5% 3%;
}

@media screen and (max-width: 768px) {
  div.job-positions div.job-positions-types {
    margin-right: 7.5%;
    width: 100%;
  }
  div.job-positions div.job-positions-sign-up {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.job-positions div.job-positions-types h3.job-positions-types-header {
    margin-top: 10%;
  }
  div.job-positions div.job-positions-types div.job-positions-types-header-underline {
    margin-bottom: 15%;
    width: 35%;
  }
div.job-positions div.job-positions-types div.job-positions-types-type {
  margin-bottom: 20%;
}
}