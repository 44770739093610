div.why-us {
  display: flex;
}

div.why-us div.why-us-image {
  background-image: url("../../../assets/images/property-management/property-management-why-us.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 50%;
}

div.why-us div.why-us-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10% 10%;
  width: 50%;
}

div.why-us div.why-us-content h3.why-us-content-header {
  color: #DE1F2C;
  font-size: 2.5em;
  font-weight: 600;
}

div.why-us div.why-us-content div.why-us-content-header-underline {
  border-bottom: 3px solid #858484;
  width: 20%;
  padding-top: 2%;
  margin-bottom: 5%;
}

div.why-us div.why-us-content p.why-us-content-text {
  color: #6F6F6F;
  font-size: 1.375em;
  line-height: 2.25rem;
}

@media screen and (max-width: 768px) {
  div.why-us {
    flex-direction: column-reverse;
  }
  div.why-us div.why-us-image {
    background-position: 50% 100%;
    height: 100vh;
    width: 100%;
  }
  div.why-us div.why-us-content {
    align-items: center;
    margin: 10% auto;
    padding: 3% 5%;
    text-align: center;
    width: 75%;
  }
}

@media screen and (max-width: 425px) {
  div.why-us div.why-us-content {
    align-items: flex-start;
    padding: 7.5% 0;
    text-align: left;
  }
  div.why-us div.why-us-image {
    background-position: 0 0;
    height: 75vh;
  }
  div.why-us div.why-us-content h3.why-us-content-header {
    font-size: 2rem;
  }
  div.why-us div.why-us-content div.why-us-content-header-underline {
    width: 30%;
  }
}