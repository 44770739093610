button.closed-properties-button {
  background-color: #414040;
  border: none;
  color: #fff;
  font-size: 2.148125rem;
  margin: 20px;
  padding: 10px 20px;
}

button.closed-properties-button:hover {
  cursor: pointer;
}

@media screen and (max-width: 425px) {
  button.closed-properties-button {
    font-size: 1.25rem;
  }
}