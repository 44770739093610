div.news-and-insights-post {
  background-color: #F2F1F1;
  padding: 5%;
}

div.news-and-insights-post div.news-and-insights-post-content {
  background-color: #fff;
  display: flex;
  position: relative;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current {
  color: #6F6F6F;
  padding: 10% 4% 5% 4%;
  width: 70%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current h3.news-and-insights-post-content-current-header {
  color: #DE1F2C;
  font-size: 2.833125rem;
  font-weight: 600;
  margin-bottom: 3%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-time-and-author {
  display: flex;
  font-size: 1.5625rem;
  font-weight: 300;
  margin-bottom: 3%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-time-and-author p.news-and-insights-post-content-current-bullet {
  font-weight: 500;
  margin: 0 3%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-time-and-author p span {
  font-style: italic;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-text {
  font-size: 1.375em;
  width: 100%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-text p {
  margin-bottom: 3%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-text p a {
  color: #De1F2C;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-text p a:visited {
  color: #DE1F2C;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-text img {
  margin-bottom: 3%;
  width: 100%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-previous-next {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-previous-next p {
  color: #DE1F2C;
  font-size: 1.375em;
  text-decoration: none;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-previous-next p:hover {
  cursor: pointer;
  text-decoration: underline;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other {
  margin: 10% 7.5% auto 0%;
  width: 30%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles {
  background-color: #ECEBEB;
  margin-bottom: 10%;
  padding: 7.5%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles h4 {
  color: #DE1F2C;
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 4%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles li a {
  color: #404041;
  font-size: 1.1875rem;
  font-weight: 500;
  text-decoration: none;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles li a:hover {
  text-decoration: underline;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles li a:visited {
  color: #404041;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles li p {
  color: #404041;
  font-size: 0.9375rem;
  font-weight: 300;
  margin-bottom: 6%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup {
  background-color: #DE1F2C;
  color: #fff;
  padding: 7.5%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup h3 {
  font-size: 2.4375rem;
  font-weight: 600;
  margin-bottom: 10%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup p {
  font-size: 1.5125rem;
  font-weight: 300;
  line-height: 1.815rem;
  margin-bottom: 10%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup form input {
  border: none;
  font-size: 1.3125rem;
  padding: 1.5%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup form input::placeholder {
  color: #202020;
  font-size: 1.5125rem;
  font-weight: 300;
  padding-left: 1%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup form button {
  background-color: #414040;
  border: none;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  padding: 1.5% 3%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper {
  height: 100%;
  padding-top: 12.5%;
  position: absolute;
  right: 0;
  width: 3%;
  top: 0;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper div.news-and-insights-post-content-other-sharing-wrapper-sticky {
  align-items: center;
  background-color: #DE1F2C;
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10%;
  right: 0;
  width: 100%;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper div.news-and-insights-post-content-other-sharing-wrapper-sticky a {
  color: #fff;
  font-size: 1.2rem;
  margin: .85rem 0;
  padding: .5rem 0;
}

div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper div.news-and-insights-post-content-other-sharing-wrapper-sticky a:visited {
  color: #fff;
}

@media screen and (max-width: 768px) {
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current {
    width: 90%;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other {
    width: 10%;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other ul.news-and-insights-post-content-other-recent-articles {
    display: none;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-signup {
    display: none;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper {
    width: 6%;
  }
}

@media screen and (max-width: 425px) {
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current {
    width: 95%;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current h3.news-and-insights-post-content-current-header {
    font-size: 2rem;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-text {
    font-size: 1.25rem;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-time-and-author {
    font-size: 1.25rem;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-current div.news-and-insights-post-content-current-previous-next p {
    font-size: 1rem;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other {
    width: 5%;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper {
    width: 8%;
  }
  div.news-and-insights-post div.news-and-insights-post-content div.news-and-insights-post-content-other div.news-and-insights-post-content-other-sharing-wrapper div.news-and-insights-post-content-other-sharing-wrapper-sticky a {
    margin: 0;
  }
}