div.admin-closed-listings {
  align-items: center;
  background-color: #F2F1F1;
  display: flex;
  flex-direction: column;
  padding: 3% 5%;
  width: 100%;
}

div.admin-closed-listings > a {
  border: 3px solid green;
  color: green;
  font-size: 1.5rem;
  padding: 1%;
  align-self: flex-end;
  text-decoration: none;
}

div.admin-closed-listings div.cNRLLS {
  height: 100%;
}

div.admin-closed-listings a.admin-carousel-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0 2%;
  width: 100%;
  text-decoration: none;
}

a.admin-carousel-card div.admin-card-image-container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 60%;
  position: relative;
  width: 100%;
}

a.admin-carousel-card div.admin-card-image-container img.admin-card-image-container-image {
  height: 100%;
  width: 100%;
  z-index: 1;
}

a.admin-carousel-card div.admin-card-image-container p.admin-card-price {
  background-color: #898888;
  bottom: 0;
  color: #fff;
  font-size: 1.125em;
  padding: 1%;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1000;
}

a.admin-carousel-card div.admin-card-content {
  align-items: center;
  color: #6F6F6F;
  display: flex;
  flex-direction: column;
  font-size: 1.125em;
  height: 40%;
}

a.admin-carousel-card div.admin-card-content p.admin-card-address {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1%;
}

a.admin-carousel-card div.admin-card-content p.admin-card-town-and-city {
  margin-bottom: 2%;
}

a.admin-carousel-card div.admin-card-content p.admin-card-town-and-city i, a.admin-carousel-card div.admin-card-content p.admin-card-square-footage i {
  color: #000;
  font-size: .8em;
  margin-right: 3px;
}

button.admin-arrow-button {
  align-self: center;
  border: none;
  height: 50px;
  width: 30px;
  text-decoration: none;
}

button.admin-arrow-button:hover {
  cursor: pointer;
}

button.rec-dot {
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #6F6F6F;
  box-shadow: none;
  height: 12px;
  margin: 0 5px;
  width: 12px;
}

button.rec-dot_active, button.QmVdP {
  background-color: #DE1F2C;
  border-radius: 50%;
  box-shadow: none;
  height: 12px;
  margin: 0 5px;
  width: 12px;
}

button.QmVdP:hover, button.QmVdP:focus, button.QmVdP:scope, button.rec-dot:hover, button.rec-dot:focus {
  cursor: pointer;
  box-shadow: none;
  outline: none;
}