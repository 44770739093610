div.listings-main {
  display: flex;
  flex-direction: column;
}

div.listings-main div.listings-main-borough {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}

div.listings-main div.listings-main-borough h2 {
  color: #6F6F6F;
  font-size: 2.833125rem;
  font-weight: 600;
  margin-top: 2%;
  text-align: center;
}

div.listings-main div.listings-main-borough div.listings-main-borough-header-underline {
  border-bottom: 3px solid #6F6F6F;
  width: 10%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing {
  background-color: #EDEBEC;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 2% 3%;
  width: 27%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing h3 {
  background-color: #7D464A;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1%;
  text-align: center;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing img {
  width: 100%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing h4 {
  color: #7D464A;
  font-size: 1.434375rem;
  font-weight: 600;
  margin: 3% 5%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p {
  color: #6F6F6F;
  font-size: 1.315rem;
  margin: 2% 5%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p span {
  font-weight: 600;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p:nth-of-type(1) {
  margin-bottom: 10%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p:nth-of-type(2) {
  border-bottom: 1px solid #6F6F6F;
  margin-bottom: 10%;
  padding-bottom: 10%;
}

div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p:nth-of-type(5) {
  margin-bottom: 8%;
}

div.listings-main div.listings-main-borough button.visible {
  background-color: #fff;
  border: 1px solid #6F6F6F;
  color: #6F6F6F;
  font-size: 1.3125rem;
  font-weight: 600;
  padding: .5% 1%;
}

div.listings-main div.listings-main-borough button.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing {
    width: 43%;
  }
}

@media screen and (max-width: 425px) {
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing {
    width: 93%;
    margin: 5%;
  }
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing h4 {
    margin: 2.5% 5%;
  }
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p {
    margin: 0 5%;
  }
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p:nth-of-type(1) {
    margin-bottom: 5%;
  }
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p:nth-of-type(2) {
    margin-bottom: 2.5%;
    padding-bottom: 2.5%;
  }
  div.listings-main div.listings-main-borough div.listings-main-borough-listings div.listings-main-borough-listings-listing p:nth-of-type(5) {
    margin-bottom: 5%;
  }
}