footer {
  background-color: #898888;
  color: #fff;
}

footer div.footer-content {
  display: flex;
  justify-content: flex-start;
  padding: 3% 10% 0 10%;
}

footer div.footer-content div.footer-content-column {
  width: 26.6666666667%;
  margin: 0 10% 0 0;
}

footer div.footer-content div.footer-content-column h3.footer-content-column-header {
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 7.5%;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li {
  font-size: 1.1875rem;
  font-weight: 300;
  margin-bottom: 7.5%;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li a.footer-content-news-and-insights-list-item-text {
  color: #fff;
  font-weight: 500;
  text-decoration: none;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li a.footer-content-news-and-insights-list-item-text:hover {
  text-decoration: underline;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li a.footer-content-news-and-insights-list-item-text:hover:visited {
  color: #fff;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li p.footer-content-news-and-insights-list-item-time {
  font-size: .9375rem;
  font-weight: 300;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li.footer-get-in-touch-list-item div.footer-get-in-touch-list-content a {
  text-decoration: none;
}

footer div.footer-content div.footer-content-column ul.footer-content-column-list li.footer-get-in-touch-list-item div.footer-get-in-touch-list-content a i {
  color: #fff;
  margin: 0 3% 0 0;
}

footer div.footer-copyright {
  border-top: 1px solid #fff;
  font-size: 1.1875em;
  font-weight: 300;
  margin-top: 5%;
  padding: 1% 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  footer div.footer-content div.footer-content-column {
    margin-right: 5%;
    padding-top: 10%;
    width: 25%;
  }
  footer div.footer-content div.footer-content-column:nth-child(1) {
    width: 75%;
  }
  footer div.footer-content div.footer-content-column:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  footer div.footer-content {
    flex-direction: column;
  }
  footer div.footer-content div.footer-content-column {
    align-items: flex-start;
    margin: 0 auto;
    width: 75%;
  }
  footer div.footer-content div.footer-content-column ul.footer-content-column-list li {
    font-weight: 500;
  }
}