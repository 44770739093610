div.admin-news-and-insights {
  align-items: center;
  background-color: #F2F1F1;
  display: flex;
  flex-direction: column;
}

div.admin-news-and-insights div.admin-news-and-insights-header {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1%;
  width: 100%;
}

div.admin-news-and-insights div.admin-news-and-insights-header h3.admin-news-and-insights-header-text {
  color: #DE1F2C;
  font-size: 2.833125rem;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

div.admin-news-and-insights div.admin-news-and-insights-header h3.admin-news-and-insights-header-text a {
  border: 3px solid green;
  color: green;
  font-size: 1.5rem;
  padding: 1%;
  text-decoration: none;
}

div.admin-news-and-insights div.admin-news-and-insights-content {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto 5% auto;
  width: 95%;
}

div.admin-news-and-insights div.admin-news-and-insights-content div {
  display: flex;
  flex-wrap: wrap;
}

div.admin-news-and-insights div.admin-news-and-insights-content div div.admin-news-and-insights-content-post {
  align-items: flex-start;
  border-bottom: 1.5px solid #E4E6E6;
  display: flex;
  flex-direction: column;
  margin: 3% 2% 0 2%;
  padding-bottom: 1%;
  width: 29%;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post img {
  width: 100%;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post a.admin-news-and-insights-content-post-header {
  color: #DE1F2C;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2%;
  text-decoration: none;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post a.admin-news-and-insights-content-post-header:hover {
  text-decoration: underline;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post a.admin-news-and-insights-content-post-header:visited {
  color: #DE1F2C;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post p.admin-news-and-insights-content-post-text {
  color: #6F6F6F;
  font-size: 1.375rem;
  line-height: 1.875rem;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post a.admin-edit-or-delete {
  color: #6F6F6F;
  font-size: 1.375rem;
  font-weight: 600;
  margin-bottom: 5%;
  text-decoration: none;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post a.admin-edit-or-delete:hover {
  text-decoration: underline;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post a.admin-edit-or-delete:visited {
  color: #6F6F6F;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post div.admin-news-and-insights-content-post-time-and-author {
  color: #6F6F6F;
  display: flex;
  font-size: 1.1875rem;
  font-weight: 300;
  justify-content: space-between;
  width: 65%;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post div.admin-news-and-insights-content-post-time-and-author p.bullet {
  color: #414040;
  font-weight: 600;
}

div.admin-news-and-insights div.admin-news-and-insights-content div.admin-news-and-insights-content-post div.admin-news-and-insights-content-post-time-and-author p span {
  font-style: italic;
}

div.admin-news-and-insights div.admin-news-and-insights-content button {
  background-color: #DE1F2C;
  border: none;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  margin: 3%;
  padding: .5% 1%;
}

div.admin-news-and-insights div.admin-news-and-insights-content button.hidden {
  display: none;
}