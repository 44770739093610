div.admin-add-form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 3% 10%;
}

div.admin-add-form h2.admin-add-form-error-message {
  color: #DE1F2C;
  font-size: 1.5rem;
  font-weight: 600;
}

div.admin-add-form form.admin-add-form-form {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

div.admin-add-form form.admin-add-form-form div {
  display: flex;
  flex-direction: column;
  margin: 1% 3%;
  width: 44%;
}

div.admin-add-form form.admin-add-form-form div.form-text {
  width: 100%;
}

div.admin-add-form form.admin-add-form-form div label {
  font-size: 1.5rem;
}

div.admin-add-form form.admin-add-form-form div textarea, div.admin-add-form form.admin-add-form-form div input, div.admin-add-form form.admin-add-form-form div select {
  font-size: 1.25rem;
  padding: 0 1%;
  resize: none;
}

div.admin-add-form button {
  font-size: 1.5rem;
  margin-bottom: 3%;
  width: 10%;
}