div.contact-us {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2% 5%;
  text-align: center;
}

div.contact-us h2.contact-us-header {
  color: #9C9C9C;
  font-size: 1.5125em;
}

div.contact-us h3.contact-us-content {
  color: #7D7D7D;
  font-size: 2.25rem;
  font-weight: 600;
  margin: 1% 0;
}

div.contact-us a {
  background-color: #DE1F2C;
  color: #fff;
  font-size: 1.3125em;
  font-weight: 600;
  margin: 10px 0;
  padding: 1% 3%;
  text-decoration: none;
}

div.contact-us a:visited {
  color: #fff;
}

@media screen and (max-width: 768px) {
  div.contact-us {
    padding: 3% 5%;
  }
}

@media screen and (max-width: 425px) {
  div.contact-us {
    padding: 7.5% 10%;
  }
}