div.property-evaluation {
  align-items: center;
  background-color: #DE1F2C;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
  text-align: center;
}

div.property-evaluation h3 {
  font-size: 2.5em;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  div.property-evaluation {
    padding: 3% 5%;
  }
}

@media screen and (max-width: 425px) {
  div.property-evaluation {
    padding: 7.5% 0;
  }
  div.property-evaluation h3 {
    font-size: 2rem;
  }
}