div.we-give-back {
  background-color: #570303;
  width: 100%;
  position: relative;
}

div.we-give-back img {
  position: absolute;
  height: 100%;
  width: 100%;
}

div.we-give-back div.we-give-back-content {
  align-items: center;
  color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2% 5%;
  position: relative;
  width: 100%;
  z-index: 2;
}

div.we-give-back div.we-give-back-content h3 {
  font-size: 2.25em;
  font-weight: 600;
  margin-bottom: 2%;
  text-align: center;
}

div.we-give-back div.we-give-back-content p {
  font-size: 1.5125em;
  font-weight: 300;
  margin-bottom: 2%;
  text-align: center;
}

@media screen and (max-width: 768px) {
  div.we-give-back div.we-give-back-content {
    padding: 3% 5%;
  }
  div.we-give-back img {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.we-give-back div.we-give-back-content {
    padding: 7.5% 10%;
  }
  div.we-give-back div.we-give-back-content p {
    margin-bottom: 5%;
  }
}