div.leasing-info {
  align-items: center;
  background-color: #413F40;
  color: #fff;
  display: flex;
}

div.leasing-info div.leasing-info-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  width: 50%;
}

div.leasing-info div.leasing-info-content div img {
  margin-bottom: 1%;
  width: 17.5%;
}

div.leasing-info div.leasing-info-content div h3 {
  font-weight: 600;
  font-size: 2.5em;
}

div.leasing-info div.leasing-info-content div div {
  border-bottom: 3px solid #fff;
  margin-bottom: 5%;
  width: 17.5%;
}

div.leasing-info div.leasing-info-content div p {
  font-size: 1.375em;
  line-height: 2.25rem;
}

div.leasing-info div.leasing-info-content div.leasing-info-content-top {
  margin-bottom: 20%;
}

div.leasing-info div.leasing-info-image {
  align-self: stretch;
  background-size: cover;
  background-image: url("../../../assets/images/leasing/leasing-info.jpeg");
  background-repeat: no-repeat;
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.leasing-info {
    flex-direction: column-reverse;
  }
  div.leasing-info div.leasing-info-content {
    margin: 10% auto;
    padding: 3% 5%;
    text-align: center;
    width: 75%;
  }
  div.leasing-info div.leasing-info-content div {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  div.leasing-info div.leasing-info-image {
    background-position: 50% 100%;
    height: 100vh;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  div.leasing-info div.leasing-info-content {
    padding: 7.5% 0;
    text-align: left;
  }
  div.leasing-info div.leasing-info-content div {
    align-items: flex-start;
    margin-bottom: 7.5%;
  }
  div.leasing-info div.leasing-info-content div img {
    margin-bottom: 3%;
  }
  div.leasing-info div.leasing-info-content div h3 {
    font-size: 2rem;
  }
  div.leasing-info div.leasing-info-content div div {
    width: 40%;
  }
  div.leasing-info div.leasing-info-image {
    background-position: 0 0;
    height: 75vh;
  }
}