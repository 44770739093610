div.sell-property-form {
  color: #fff;
  display: flex;
}

div.sell-property-form div.sell-property-form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2% 5%;
  width: 50%;
}

div.sell-property-form div.sell-property-form-content h3.sell-property-form-content-header {
  font-size: 2.5em;
  font-weight: 600;
  line-height: 3rem;
}

div.sell-property-form div.sell-property-form-content div.sell-property-form-content-header-underline {
  border-bottom: 2px solid #858484;
  margin-bottom: 3%;
  padding-top: 3%;
  width: 35%;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.375em;
  justify-content: space-between;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form label {
  margin-bottom: 1%;
  width: 100%;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form input {
  border: none;
  font-size: 1.375rem;
  height: 2.6875rem;
  margin-bottom: 3%;
  padding-left: 1%;
  width: 100%;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form .sell-property-form-content-form-input-name {
  width: 45%;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form textarea {
  border: none;
  font-size: 1.375rem;
  height: 5.5rem;
  margin-bottom: 3%;
  padding-left: 1%;
  resize: none;
  width: 100%;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form input:focus, div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form textarea:focus {
  outline: none;
}

div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form button, p.sell-property-form-content-form-button {
  background-color: #898888;
  border: none;
  color: #fff;
  font-size: 1.18125rem;
  font-weight: 600;
  padding: 1.5% 1%;
  text-align: center;
  width: 35%;
}

div.sell-property-form img.sell-property-form-image {
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.sell-property-form {
    flex-direction: column;
  }
  div.sell-property-form div.sell-property-form-content {
    margin: 0 auto;
    padding: 3% 5%;
    width: 75%;
  }
  div.sell-property-form img.sell-property-form-image {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.sell-property-form div.sell-property-form-content {
    padding: 7.5% 0;
  }
  div.sell-property-form div.sell-property-form-content h3.sell-property-form-content-header {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 5%;
    text-align: center;
  }
  div.sell-property-form div.sell-property-form-content div.sell-property-form-content-header-underline {
    display: none;
  }
  div.sell-property-form div.sell-property-form-content form.sell-property-form-content-form button, p.sell-property-form-content-form-button {
    font-size: 1rem;
    margin-top: 3%;
    padding: 2% 3%;
    width: 40%;
  }
}