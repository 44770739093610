div.property-management-contact-us {
  background-color: #DE1F2C;
  display: flex;
}

div.property-management-contact-us div.property-management-contact-us-content {
  align-items: flex-start;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 7.5%;
  width: 50%;
}

div.property-management-contact-us div.property-management-contact-us-content h3.property-management-contact-us-content-header {
  font-size: 2.5em;
  font-weight: 300;
}

div.property-management-contact-us div.property-management-contact-us-content h3.property-management-contact-us-content-header span {
  font-weight: 600;
}

div.property-management-contact-us div.property-management-contact-us-content div.property-management-contact-us-content-header-underline {
  border-bottom: 3px solid #858484;
  margin-bottom: 4%;
  padding-top: 4%;
  width: 20%;
}

div.property-management-contact-us div.property-management-contact-us-content p.property-management-contact-us-content-description {
  font-size: 1.375em;
  margin-bottom: 5%;
}

div.property-management-contact-us div.property-management-contact-us-content a {
  background-color: #898888;
  border: none;
  color: #fff;
  font-size: 1.181875em;
  font-weight: 600;
  padding: 1% 2%;
  text-decoration: none;
}

div.property-management-contact-us div.property-management-contact-us-content a:visited {
  color: #fff;
}

div.property-management-contact-us img.property-management-contact-us-image {
  width: 50%;
}

@media screen and (max-width: 768px) {
  div.property-management-contact-us {
    flex-direction: column;
  }
  div.property-management-contact-us div.property-management-contact-us-content {
    align-items: center;
    margin: 10% auto;
    padding: 3% 5%;
    text-align: center;
    width: 75%;
  }
  div.property-management-contact-us img.property-management-contact-us-image {
    height: 75vh;
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  div.property-management-contact-us div.property-management-contact-us-content {
    align-items: flex-start;
    padding: 7.5% 0;
    text-align: left;
  }
  div.property-management-contact-us img.property-management-contact-us-image {
    height: 50vh;
  }
  div.property-management-contact-us div.property-management-contact-us-content h3.property-management-contact-us-content-header {
    font-size: 2rem;
  }
  div.property-management-contact-us div.property-management-contact-us-content div.property-management-contact-us-content-header-underline {
    width: 30%;
  }
}