@font-face {
  src: url("./assets/fonts/CronosPro-Regular.otf");
  font-family: CronosPro;
}

@font-face {
  src: url("./assets/fonts/CronosPro-Lt.otf");
  font-family: CronosPro;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  src: url("./assets/fonts/CronosPro-Semibold.otf");
  font-family: CronosPro;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url("./assets/fonts/CronosPro-Bold.otf");
  font-family: CronosPro;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  src: url("./assets/fonts/CronosPro-Italic.otf");
  font-family: CronosPro;
  font-style: italic;
  font-weight: 500;
}

@font-face {
  src: url("./assets/fonts/CronosPro-LtIt.otf");
  font-family: CronosPro;
  font-style: italic;
  font-weight: 300;
}

* {
  box-sizing: border-box;
  font-family: CronosPro;
  list-style-type: none;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

button:hover {
  cursor: pointer;
}