div.closed-properties {
  align-items: center;
  background-color: #F2F1F1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

div.closed-properties h3.closed-properties-header {
  color: #DE1F2C;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}

div.closed-properties p.line-one {
  border-bottom: 3px solid #6F6F6F;
  padding-top: 1%;
  margin-bottom: 2%;
  width: 5%;
}

@media screen and (max-width: 425px) {
  div.closed-properties h3.closed-properties-header {
    font-size: 2.1rem;
    margin-bottom: 5%;
  }
  div.closed-properties p.line-one {
    display: none;
  }
}