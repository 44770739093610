div.ten-thirty-one-exchange-banner {
  align-items: center;
  background-image: url('../../../assets/images/multiple-pages/1031-exchange-background.jpg');
  background-repeat: repeat-y;
  display: flex;
  position: relative;
}

div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4% 10% 7% 10%;
  width: 50%;
  z-index: 2;
}

div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content img {
  border-bottom: 3px solid #858484;
  margin-bottom: 2%;
  padding-bottom: 2%;
}

div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content h3 {
  color: #d8322d;
  font-size: 3.98625em;
  line-height: 3.4375rem;
}

div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content p {
  color: #6F6F6F;
  font-size: 1.375em;
  line-height: 2.25rem;
}

div.ten-thirty-one-exchange-banner img.ten-thirty-one-exchange-banner-image {
  height: 100%;
  width: 50%;
  z-index: 2;
}

@media screen and (max-width: 768px) {
  div.ten-thirty-one-exchange-banner {
    background-image: none;
    background-color: #f2f0f1;
    flex-direction: column;
    padding: 3% 5%;
    text-align: center;
  }
  div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content {
    align-items: center;
    padding: 0;
    width: 90%;
    margin: 5% auto 5% auto;
  }
  div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content h3 {
    font-size: 3rem;
  }
  div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content img {
    margin-bottom: 5%;
    padding-bottom: 5%;
    width: 15%;
  }
  div.ten-thirty-one-exchange-banner img.ten-thirty-one-exchange-banner-image {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  div.ten-thirty-one-exchange-banner {
    padding: 7.5% 10%;
  }
  div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content h3 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  div.ten-thirty-one-exchange-banner div.ten-thirty-one-exchange-banner-content img {
    width: 25%;
  }
}