div.job-position {
  background-color: #fff;
  display: flex;
  margin: 3% 5%;
}

div.job-position div.job-position-type {
  color: #404041;
  margin: 3% 0% 5% 7.5%;
  width: 70%;
}

div.job-position div.job-position-type h3.job-position-type-header {
  font-size: 2.833125rem;
  font-weight: 600;
}

div.job-position div.job-position-type div.job-position-type-header-underline {
  border-bottom: 3px solid #404041;
  margin-bottom: 5%;
  padding-top: 1%;
  width: 10%;
}

div.job-position div.job-position-type h4 {
  color: #404041;
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

div.job-position div.job-position-type div.job-description {
  color: #6F6F6F;
  display: flex;
  flex-direction: column;
  font-size: 1.375rem;
  line-height: 1.875rem;
}

div.job-position div.job-position-type div.job-description p {
  margin-bottom: 1.5rem;
}

div.job-position div.job-position-type div.job-description p a {
  color: #DE1F2C;
}

div.job-position div.job-position-type div.job-description p a:visited {
  color: #DE1F2C;
}

div.job-position div.job-position-type div.job-description ul {
  margin-bottom: 1rem;
}

div.job-position div.job-position-type div.job-description li {
  list-style-type: disc;
}

div.job-position div.job-position-type form {
  border-top: 1px solid #E4E6E6;
  padding-top: 5%;
}

div.job-position div.job-position-type form h4 {
  color: #DE1F2C;
  font-size: 1.375rem;
  font-style: italic;
}

div.job-position div.job-position-type form input, div.job-position div.job-position-type form select {
  background-color: #E6E6E6;
  border: 1.5px solid #B5B5B5;
  font-size: 1.25rem;
  margin-bottom: 3%;
  padding: .5% 1%;
  width: 48.5%;
}

div.job-position div.job-position-type form input::placeholder, div.job-position div.job-position-type form select {
  color: #6F6F6F;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 300;
}

div.job-position div.job-position-type form input:focus, div.job-position div.job-position-type form select:focus {
  border: 1.5px solid #DE1F2C;
  outline: none;
}

div.job-position div.job-position-type form .left-input {
  margin-right: 1.5%;
}

div.job-position div.job-position-type form .right-input {
  margin-left: 1.5%;
}

div.job-position div.job-position-type form select:hover {
  cursor: pointer;
}

div.job-position div.job-position-type form input.resume-file {
  background-color: #fff;
  border: none;
  display: block;
}

div.job-position div.job-position-type form button {
  background-color: #DE1F2C;
  border: none;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  padding: 1% 3%;
}

div.job-position div.job-position-type form p {
  background-color: #DE1F2C;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  padding: 1% 3%;
  text-align: center;
  width: 47.5%;
}

div.job-position div.job-position-type form p:hover {
  cursor: pointer;
}

div.job-position div.job-position-sign-up {
  background-color: #DE1F2C;
  color: #fff;
  margin: 10% 3% auto 4%;
  padding: 4% 2%;
  width: 30%;
}

div.job-position div.job-position-sign-up h3 {
  font-size: 2.4375rem;
  font-weight: 600;
  margin-bottom: 10%;
}

div.job-position div.job-position-sign-up p {
  font-size: 1.5125rem;
  font-weight: 300;
  line-height: 1.815rem;
  margin-bottom: 10%;
}

div.job-position div.job-position-sign-up form input {
  border: none;
  font-size: 1.3125rem;
  padding: 1.5%;
}

div.job-position div.job-position-sign-up form input::placeholder {
  color: #202020;
  font-size: 1.5125rem;
  font-weight: 300;
  padding-left: 1%;
}

div.job-position div.job-position-sign-up form button {
  background-color: #414040;
  border: none;
  color: #fff;
  font-size: 1.3125rem;
  font-weight: 600;
  padding: 1.5% 3%;
}

@media screen and (max-width: 768px) {
  div.job-position div.job-position-type {
    padding: 7.5%;
    width: 100%;
    margin: 0;
  }
  div.job-position div.job-position-sign-up {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  div.job-position div.job-position-type div.job-position-type-header-underline {
    margin-bottom: 15%;
    width: 35%;
  }
  div.job-position div.job-position-type div.job-description {
    font-size: 1.25rem;
  }
  div.job-position div.job-position-type form button {
    padding: 2% 4%;
  }
}